.commands-drop-downs-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.commands-drop-downs-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.commands-drop-downs-list {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.commands-drop-downs-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 1px;
  flex-direction: column;
}
 
.commands-drop-downs-trigger1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-faq1-question1 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
 
.commands-drop-downs-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-icon10 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-icon12 {
  fill: var(--dl-color-theme-secondary1);
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-container13 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.commands-drop-downs-text100 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
 
.commands-drop-downs-text101 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text103 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text104 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text106 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text107 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text109 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text110 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text112 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text113 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text115 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text116 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text118 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text119 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text121 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text122 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text124 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text125 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text127 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text128 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text130 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text131 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text133 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text134 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 1px;
  flex-direction: column;
}
 
.commands-drop-downs-trigger2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-faq2-question1 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
 
.commands-drop-downs-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-icon14 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-icon16 {
  fill: var(--dl-color-theme-secondary1);
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-container16 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.commands-drop-downs-text137 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
 
.commands-drop-downs-text138 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text140 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text141 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text143 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text144 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text146 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text147 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text149 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text151 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text154 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text157 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text160 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text163 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text164 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text166 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text167 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text169 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text170 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text172 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text173 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text175 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text176 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text178 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text179 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text181 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text182 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text184 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text185 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-faq3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 1px;
  flex-direction: column;
}
 
.commands-drop-downs-trigger3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-faq2-question2 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
 
.commands-drop-downs-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-icon18 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-icon20 {
  fill: var(--dl-color-theme-secondary1);
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-container19 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.commands-drop-downs-text188 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
 
.commands-drop-downs-text189 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text191 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text192 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text194 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text195 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text197 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text199 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text202 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text203 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text205 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text206 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text208 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text209 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text211 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text212 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text214 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text217 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text220 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text223 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-faq4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 1px;
  flex-direction: column;
}
 
.commands-drop-downs-trigger4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-faq2-question3 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
 
.commands-drop-downs-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-icon22 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-icon24 {
  fill: var(--dl-color-theme-secondary1);
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-container22 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.commands-drop-downs-text224 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
 
.commands-drop-downs-text225 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text228 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text231 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text234 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text237 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text240 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-faq5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 1px;
  flex-direction: column;
}
 
.commands-drop-downs-trigger5 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-faq1-question2 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
 
.commands-drop-downs-icons-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-icon26 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-icon28 {
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-container25 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.commands-drop-downs-text244 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
 
.commands-drop-downs-text245 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text247 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text248 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text250 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text251 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text253 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text254 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text256 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text257 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-faq6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 1px;
  flex-direction: column;
}
 
.commands-drop-downs-trigger6 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-faq1-question3 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
 
.commands-drop-downs-icons-container6 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.commands-drop-downs-icon30 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-icon32 {
  fill: var(--dl-color-theme-secondary1);
  width: 24px;
  height: 24px;
}
 
.commands-drop-downs-container28 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.commands-drop-downs-text260 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
 
.commands-drop-downs-text261 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text263 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text264 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text266 {
  font-weight: 700;
  text-decoration: underline;
}
 
.commands-drop-downs-text267 {
  display: inline-block;
}
 
.commands-drop-downs-text268 {
  display: inline-block;
}
 
.commands-drop-downs-text271 {
  display: inline-block;
}
 
.commands-drop-downs-text272 {
  display: inline-block;
}
 
.commands-drop-downs-text275 {
  display: inline-block;
}
 
.commands-drop-downs-text278 {
  display: inline-block;
}
 

 
@media(max-width: 991px) {
  .commands-drop-downs-container10 {
    flex-direction: column;
  }
}
 
@media(max-width: 479px) {
  .commands-drop-downs-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
