.footer-footer {
    gap: 80px;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-fiveunits);
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    background-color: #000918;
}

.footer-max-width {
    gap: var(--dl-space-space-threeunits);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer-content {
    gap: 32px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
}

.footer-logo {
    gap: 24px;
    width: auto;
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
}

.footer-image1 {
    width: var(--dl-size-size-xlarge);
    height: auto;
}

.footer-links {
    gap: var(--dl-space-space-twounits);
    display: flex;
    align-items: flex-start;
}

.footer-link1 {
    text-decoration: none;
}

.footer-link2 {
    text-decoration: none;
}

.footer-link3 {
    text-decoration: none;
}

.footer-link4 {
    text-decoration: none;
}

.footer-social-links {
    gap: var(--dl-space-space-unit);
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
}

.footer-link5 {
    display: contents;
}

.footer-image {
    width: 24px;
    object-fit: cover;
    text-decoration: none;
}

.footer-credits {
    gap: var(--dl-space-space-twounits);
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
}

.footer-divider {
    background-color: var(--dl-color-theme-secondary1);
}

.footer-row {
    gap: 24px;
    display: flex;
    align-items: flex-start;
}

.footer-footer-links {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
}

.footer-content32 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
    display: inline-block;
}

.footer-link113 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
    display: inline-block;
    text-decoration: none;
}

.footer-text14 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.footer-text15 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
    display: inline-block;
    text-decoration: none;
}

.footer-text16 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.footer-text17 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.footer-text18 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.footer-text19 {
    color: #132441;
    display: inline-block;
}

@media(max-width: 991px) {
    .footer-logo {
        width: auto;
    }
}

@media(max-width: 767px) {
    .footer-content {
        flex-direction: column;
    }

    .footer-row {
        flex-direction: column;
    }

    .footer-footer-links {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}

@media(max-width: 479px) {
    .footer-max-width {
        gap: var(--dl-space-space-oneandhalfunits);
    }

    .footer-content {
        width: 100%;
    }

    .footer-links {
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}