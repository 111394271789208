.home-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/daiki-webpage-background-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}

.home-link1 {
  fill: var(--dl-color-theme-accent1);
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
}

.home-link2 {
  display: inline-block;
  text-decoration: none;
}

.home-text14 {
  display: inline-block;
  text-decoration: none;
}

.home-text15 {
  display: inline-block;
  text-decoration: none;
}

.home-text16 {
  display: inline-block;
}

.home-text17 {
  display: inline-block;
  text-decoration: none;
}

.home-text18 {
  display: inline-block;
  text-decoration: none;
}

.home-text19 {
  display: inline-block;
  text-decoration: none;
}

.home-text20 {
  display: inline-block;
  text-decoration: none;
}

.home-text21 {
  display: inline-block;
}

.home-container2 {
  flex: 0 0 auto;
  width: 85%;
  height: auto;
  display: flex;
  margin-top: 168px;
  align-items: flex-start;
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: rgba(0, 9, 24, 0.8);
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
}

.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: flex-start;
  border-radius: 0px;
  animation-name: none;
  flex-direction: column;
  animation-delay: 0s;
  background-size: cover, auto;
  justify-content: flex-end;
  background-image: radial-gradient(circle at center, rgba(0, 9, 24, 0.6) 0.00%, rgba(0, 9, 24, 0.2) 98.00%), url("/daiki6-1300w.png");
  background-repeat: repeat, no-repeat;
  animation-duration: 300ms;
  animation-direction: normal;
  background-position: bottom, center;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.home-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}

.home-text22 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-size: 40px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
}

.home-link3 {
  display: contents;
}

.home-exclude {
  width: var(--dl-size-size-xxlarge);
  height: auto;
  z-index: 5;
  box-sizing: content-box;
  margin-top: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  text-decoration: none;
}

.home-exclude:hover {
  width: 295px;
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 400ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.home-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  border-radius: 0px;
  flex-direction: column;
  justify-content: center;
  background-color: #000918;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
}

.home-container6 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
}

.home-navlink1 {
  display: contents;
}

.home-container7 {
  flex: 0 0 auto;
  width: 250px;
  height: 200px;
  display: flex;
  box-shadow: 2px 2px 10px 0px #6141ac;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-theme-accent2);
  border-width: 4px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-theme-accent2);
}

.home-container7:hover {
  box-shadow: 2px 2px 10px 0px #0554d2;
  border-color: var(--dl-color-theme-primary2);
  animation-name: pulse;
  animation-delay: 0s;
  background-color: var(--dl-color-theme-primary2);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.home-icon1 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}

.home-text25 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 18px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Poppins";
}

.home-navlink2 {
  display: contents;
}

.home-container8 {
  flex: 0 0 auto;
  width: 250px;
  height: 200px;
  display: flex;
  box-shadow: 2px 2px 10px 0px #6141ac;
  transition: 0.3s;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  border-color: var(--dl-color-theme-accent2);
  border-width: 4px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-theme-accent2);
}

.home-container8:hover {
  box-shadow: 2px 2px 10px 0px #0554d2;
  border-color: var(--dl-color-theme-primary2);
  animation-name: pulse;
  animation-delay: 0s;
  background-color: var(--dl-color-theme-primary2);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.home-icon3 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}

.home-text28 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Poppins";
}

.home-navlink3 {
  display: contents;
}

.home-container9 {
  flex: 0 0 auto;
  width: 250px;
  height: 200px;
  display: flex;
  box-shadow: 2px 2px 10px 0px #6141ac;
  transition: 0.3s;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  border-color: var(--dl-color-theme-accent2);
  border-width: 4px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-theme-accent2);
}

.home-container9:hover {
  box-shadow: 2px 2px 10px 0px #0554d2;
  border-color: var(--dl-color-theme-primary2);
  animation-name: pulse;
  animation-delay: 0s;
  background-color: var(--dl-color-theme-primary2);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.home-icon5 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}

.home-text31 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Poppins";
}

.home-text34 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}

.home-text35 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}

.home-text36 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}

.home-text37 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}

.home-text38 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  display: inline-block;
  text-decoration: none;
}

.home-link11 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  display: inline-block;
  text-decoration: none;
}

.home-content3 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  display: inline-block;
}

@media(max-width: 991px) {
  .home-text22 {
    font-size: 35px;
  }

  .home-exclude {
    width: var(--dl-size-size-xlarge);
  }

  .home-container5 {
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

@media(max-width: 767px) {
  .home-container3 {
    background-image: radial-gradient(circle at center, rgba(0, 9, 24, 0.6) 1.00%, rgba(0, 9, 24, 0.2) 96.00%), url("/daiki6-1300w.png");
  }

  .home-container7 {
    width: 200px;
  }

  .home-container8 {
    width: 200px;
  }

  .home-container9 {
    width: 200px;
  }
}

@media(max-width: 479px) {
  .home-container2 {
    background-color: rgba(0, 9, 24, 0.9);
  }

  .home-container3 {
    background-image: radial-gradient(circle at center, rgba(0, 9, 24, 0.6) 1.00%, rgba(0, 9, 24, 0.2) 96.00%), url("/daiki6-1300w.png");
  }

  .home-container5 {
    height: 650px;
  }

  .home-container6 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .home-container7 {
    width: 250px;
    margin-left: 0px;
  }

  .home-container8 {
    width: 250px;
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }

  .home-container9 {
    width: 250px;
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}