.page-not-found-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/daiki-webpage-background-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}

.page-not-found-web-alerts {
  flex: 0 0 auto;
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgb(255, 246, 0) 69.00%, rgb(0, 9, 24) 100.00%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.page-not-found-text10 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}

.page-not-found-text11 {
  font-size: 16px;
}

.page-not-found-text12 {
  font-size: 16px;
}

.page-not-found-text13 {
  font-size: 16px;
}

.page-not-found-text14 {
  font-size: 16px;
}

.page-not-found-nav-bar {
  top: 0;
  flex: 0 0 auto;
  left: 0;
  width: 100%;
  height: 75px;
  display: flex;
  z-index: 100;
  align-self: stretch;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: space-between;
  background-color: #000918;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
}

.page-not-found-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.page-not-found-image1 {
  width: var(--dl-size-size-medium);
  height: auto;
}

.page-not-found-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.page-not-found-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.page-not-found-link11 {
  fill: var(--dl-color-theme-accent1);
  color: rgb(255, 255, 255);
  display: none;
}

.page-not-found-link21 {
  display: none;
}

.page-not-found-link31 {
  display: none;
}

.page-not-found-link41 {
  display: none;
}

.page-not-found-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.page-not-found-action11 {
  display: flex;
  border-color: var(--dl-color-theme-primary2);
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-theme-primary2);
}

.page-not-found-burger-menu {
  display: none;
}

.page-not-found-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.page-not-found-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000918;
}

.page-not-found-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.page-not-found-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.page-not-found-logo {
  height: 3rem;
}

.page-not-found-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-not-found-icon12 {
  fill: var(--dl-color-theme-secondary1);
  width: 24px;
  height: 21px;
}

.page-not-found-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.page-not-found-link12 {
  display: none;
  text-decoration: none;
}

.page-not-found-link22 {
  display: none;
  text-decoration: none;
}

.page-not-found-link32 {
  display: none;
  text-decoration: none;
}

.page-not-found-link42 {
  display: none;
  text-decoration: none;
}

.page-not-found-buttons2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}

.page-not-found-action12 {
  display: flex;
  border-color: var(--dl-color-theme-primary2);
  flex-direction: row;
  background-color: var(--dl-color-theme-primary2);
}

.page-not-found-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}

.page-not-found-container2 {
  flex: 0 0 auto;
  width: 85%;
  height: auto;
  display: flex;
  margin-top: 168px;
  align-items: flex-start;
  border-radius: 45px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: rgba(0, 9, 24, 0.8);
}

.page-not-found-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: flex-start;
  border-radius: 45px;
  animation-name: none;
  flex-direction: column;
  animation-delay: 0s;
  background-size: cover, auto;
  justify-content: flex-end;
  background-image: radial-gradient(circle at center, rgba(0, 9, 24, 0.6) 1.00%, rgba(0, 9, 24, 0.2) 96.00%),
    url("/daiki6-1300w.png");
  background-repeat: repeat, no-repeat;
  animation-duration: 300ms;
  animation-direction: normal;
  background-position: bottom, center;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.page-not-found-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}

.page-not-found-text20 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-size: 40px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
}

.page-not-found-action13 {
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-theme-primary2);
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-theme-primary2);
}

@media(max-width: 991px) {
  .page-not-found-text20 {
    font-size: 35px;
  }
}

@media(max-width: 767px) {
  .page-not-found-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .page-not-found-desktop-menu {
    display: none;
  }

  .page-not-found-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-not-found-icon10 {
    fill: #ffffff;
    width: 24px;
    height: 24px;
  }
}

@media(max-width: 479px) {
  .page-not-found-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }

  .page-not-found-icon10 {
    width: 24px;
    height: 24px;
  }

  .page-not-found-mobile-menu {
    padding: var(--dl-space-space-unit);
  }

  .page-not-found-container2 {
    background-color: rgba(0, 9, 24, 0.9);
  }

  .page-not-found-container3 {
    background-image: radial-gradient(circle at center, rgba(0, 9, 24, 0.6) 1.00%, rgba(0, 9, 24, 0.2) 96.00%),
      url("/daiki6-1300w.png");
  }
}