.web-alerts-web-alerts {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-image: linear-gradient(180deg, rgb(255, 246, 0) 86.00%, rgb(0, 9, 24) 100.00%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.web-alerts-text10 {
  text-align: center;
}

.web-alerts-navlink {
  margin-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-theme-primary2);
}

.web-alerts-text12 {
  display: inline-block;
}

.web-alerts-text13 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}

@media(max-width: 479px) {
  .web-alerts-web-alerts {
    height: auto;
  }

  .web-alerts-text10 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
}