.contact-container1 {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-image: url("/daiki-webpage-background-1500w.png");
    background-repeat: no-repeat;
    background-position: center;
}

.contact-text10 {
    display: inline-block;
}

.contact-link1 {
    fill: var(--dl-color-theme-accent1);
    color: rgb(255, 255, 255);
    display: inline-block;
    text-decoration: none;
}

.contact-link2 {
    display: inline-block;
    text-decoration: none;
}

.contact-text15 {
    display: inline-block;
    text-decoration: none;
}

.contact-text16 {
    display: inline-block;
    text-decoration: none;
}

.contact-text17 {
    display: inline-block;
}

.contact-text18 {
    display: inline-block;
    text-decoration: none;
}

.contact-text19 {
    display: inline-block;
    text-decoration: none;
}

.contact-text20 {
    display: inline-block;
    text-decoration: none;
}

.contact-text21 {
    display: inline-block;
    text-decoration: none;
}

.contact-container2 {
    flex: 0 0 auto;
    width: 85%;
    height: auto;
    display: flex;
    margin-top: 168px;
    align-items: flex-start;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
    background-color: rgba(0, 9, 24, 0.8);
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
}

.contact-container3 {
    flex: 0 0 auto;
    width: 100%;
    height: 350px;
    display: flex;
    align-items: flex-start;
    border-radius: 0px;
    animation-name: none;
    flex-direction: column;
    animation-delay: 0s;
    background-size: cover, auto;
    justify-content: flex-end;
    background-image: radial-gradient(circle at center, rgba(0, 9, 24, 0.6) 1.00%, rgba(0, 9, 24, 0.2) 96.00%), url("/daiki6-1300w.png");
    background-repeat: repeat, no-repeat;
    animation-duration: 300ms;
    animation-direction: normal;
    background-position: bottom, center;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

.contact-container4 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
}

.contact-text22 {
    fill: var(--dl-color-theme-neutral-light);
    color: var(--dl-color-theme-neutral-light);
    font-size: 40px;
    font-style: normal;
    font-family: "Poppins";
    font-weight: 700;
}

.contact-container5 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    background-color: #000918;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
}

.contact-contact20 {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
}

.contact-max-width {
    gap: var(--dl-space-space-twounits);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact-section-title {
    gap: var(--dl-space-space-unit);
    width: auto;
    display: flex;
    max-width: 800px;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
}

.contact-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
}

.contact-text25 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-family: "Poppins";
}

.contact-row {
    gap: var(--dl-space-space-threeunits);
    display: flex;
    align-self: stretch;
    align-items: center;
}

.contact-content2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact-icon1 {
    fill: #D9D9D9;
}

.contact-contact-info1 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
}

.contact-content4 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: stretch;
    align-items: stretch;
    flex-direction: column;
}

.contact-text26 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    align-self: stretch;
    text-align: center;
    font-family: "Poppins";
}

.contact-text27 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    text-align: center;
    font-family: "Poppins";
}

.contact-email {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    text-align: center;
    font-family: "Poppins";
    font-weight: bold;
    text-decoration: underline none;
}

.contact-content5 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact-icon3 {
    fill: #D9D9D9;
}

.contact-contact-info2 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
}

.contact-content6 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: stretch;
    align-items: stretch;
    flex-direction: column;
}

.contact-text32 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    align-self: stretch;
    text-align: center;
    font-family: "Poppins";
}

.contact-text33 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    text-align: center;
    font-family: "Poppins";
}

.contact-phone {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    text-align: center;
    font-family: "Poppins";
    font-weight: bold;
    text-decoration: none;
}

.contact-content7 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact-icon5 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
}

.contact-contact-info3 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
}

.contact-content8 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: stretch;
    align-items: stretch;
    flex-direction: column;
}

.contact-text38 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    align-self: stretch;
    text-align: center;
    font-family: "Poppins";
}

.contact-text39 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    text-align: center;
    font-family: "Poppins";
}

.contact-text42 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    text-align: center;
    font-family: "Poppins";
    font-weight: bold;
}

.contact-text47 {
    color: #132441;
    display: inline-block;
}

.contact-text48 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.contact-text49 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.contact-text50 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.contact-text51 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    display: inline-block;
}

.contact-text52 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
    display: inline-block;
    text-decoration: none;
}

.contact-link11 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
    display: inline-block;
    text-decoration: none;
}

.contact-content3 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
    display: inline-block;
}

@media(max-width: 991px) {
    .contact-text22 {
        font-size: 35px;
    }

    .contact-container5 {
        align-self: center;
        align-items: center;
        justify-content: center;
    }
}

@media(max-width: 767px) {
    .contact-container3 {
        background-image: url("/daiki6-1300w.png");
    }

    .contact-row {
        flex-direction: column;
    }
}

@media(max-width: 479px) {
    .contact-container2 {
        background-color: rgba(0, 9, 24, 0.9);
    }

    .contact-container3 {
        background-image: url("/daiki6-1300w.png");
    }

    .contact-container5 {
        height: auto;
    }

    .contact-row {
        align-items: stretch;
    }
}